<template>
  <div class="color ">
      <div class="top  container-fluid centrado">
        <div class=" row text-center">
            <div class="col-md-4 offset-md-4 ">
                <div class="fondo-login sombra">
                    <div class="titulo">
                        <div class="text-align-center">
                            <img class="img" src="../assets/logo_T2.png" alt="">
                        </div>
                        <h1 class="mt-3 color_titulo"><b> Inicio de sesion</b></h1>
                    </div>
                    <div class="input mb-3 mt-4">
                        <input type="text" class="sombra form-control tamaño_input sombra p-4 bg-light rounded" placeholder="usuario" aria-label="Username" aria-describedby="basic-addon1">
                    </div>
                    <div class="input mb-3">
                        <input type="text" class="sombra form-control tamaño_input sombra p-4 bg-light rounded" placeholder="contraseña" aria-label="whatsapp" aria-describedby="basic-addon1" >
                    </div>
                    
                    <button class="btn color-b mt-3" @click="goToStep(7)">Iniciar sesion</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.color{
    background-color: rgba(140, 246, 167, 0.904);
    height: 765px;
}
.top{
    margin-top: 1%;
}
.fondo-login{
    background-color: rgba(255, 255, 255, 0.739);
    width: 100%;
    border-radius: 30px;
    
    
}
.tamaño_input{
    width:60%;
    margin-left: 20%;
    margin-right: 15%;
    
}
.img{
    max-width: 60%;
    max-height: 60%;
    margin-top: 5%;
    
}
.color-b{
      background-color: rgb(105, 57, 236);
      color: rgb(242, 212, 251);
      border-radius: 10px;
      width: 300px;
      height: 50px;
      font-size: 25px;
      margin-bottom: 30%;
      
  }
  .sombra{
      box-shadow: 0 2px 2px 0 #c574eb32, 0 0px 8px 0 rgba(188, 45, 255, 0.322);
    }
    .color_titulo{
        color: rgb(105, 57, 236);
    }
</style>