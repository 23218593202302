<template>
  <div class="container " style="margin-top: 150px"> 
    <div class="mt-5"><br>
        <h3 class="text-center">CUMPLEAÑEROS DEL MES:</h3>
        <div class="row justify-content-center mt-5">
            <div class="col-md-2" v-for="item of rows" :key="item.id" >
                <img :src="item.img!= 'null' && item.img!= null ? item.img : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCpawWq8L8kk4tzxZ6h-svcCsRbQFvUk2KfYnaBg7MOcqvhYdIJUL60i5pBI-f_whkQ90&usqp=CAU'" class="img-responsive rounded-pill borde" alt="...">
                <div class="card-body">
                    <h4 style="height:50px; font-weight: bold" class="card-title">{{ item.name }}</h4>
                    <!-- <p class="card-text"><span style='text-decoration: underline'>&nbsp;{{ item.age + ' años' }} &nbsp;</span></p> -->
                    <span class="card-text">{{ getDay(item.birthday) }}</span>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            rows:[ ],

        }
    },
    async mounted(){
        await this.getData()
    },
    methods:{
        getDay(date){
            let month = date.substr(5,2)
            let day = date.substr(8,2)
            let textDay = ''
            if(month == 1)
                textDay = "Enero"
            if(month == 2)
                textDay = "Febrero"
            if(month == 3)
                textDay = "Marzo"
            if(month == 4)
                textDay = "Abril"
            if(month == 5)
                textDay = "Mayo"
            if(month == 6)
                textDay = "Junio"
            if(month == 7)
                textDay = "Julio"
            if(month == 8)
                textDay = "Agosto"
            if(month == 9)
                textDay = "Septiembre"
            if(month == 10)
                textDay = "Octubre"
            if(month == 11)
                textDay = "Noviembre"
            if(month == 12)
                textDay = "Diciembre"

            return Number(day) + ' de ' + textDay
        },
        async getData(){
            let req = await fetch(this.$store.state.url + "users")
            let rows = await req.json()
            this.rows = rows
            console.log('getdata', this.rows)
        }
    }
}
</script>

<style>

</style>